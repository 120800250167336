import { createSlice } from "@reduxjs/toolkit"
import { errorService } from "src/services/error.service"
import { userService } from "src/services/user.service"
import { AppThunk } from "src/store/store"
import { messages } from "src/utils/constants/messages"
import { TYPES } from "src/utils/constants/types.constants"
import { SeverityEnum } from "src/utils/enums/toast.enum"
import { toastHelpers } from "src/utils/helpers/toast.helpers"
import { createToast } from "./toastAuth.reducer"

const initialState: StateUser = {
  userOrg: {},
  users: [],
  me: {}
}

const userSlide = createSlice({
  name: TYPES.USER,
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state.userOrg = action.payload
    },
    updateUsers: (state, action) => {
      state.users = action.payload
    },
    updateMe: (state, action) => {
      state.me = action.payload
    }
  }
})

export const { updateUser, updateUsers, updateMe } = userSlide.actions
export const callApiGetUser = (): AppThunk => async (dispatch, getState) => {
  userService
    .getUser()
    .then(res => {
      if (!res.data.ok) {
        const error = errorService.handleServerError(res)
        toastHelpers.handleToast(dispatch, error, SeverityEnum.ERROR)
        return
      }
      const orgRes = res.data.data.org
      const { me } = res.data.data
      dispatch(updateUser(orgRes))
      dispatch(updateMe(me))
    })
    .catch(err => {
      const toastParams: ToastParams = {
        messages: messages.user.error_load_user,
        severity: SeverityEnum.WARNING
      }
      dispatch(createToast(toastParams))
    })
}
export const callApiGetUsers =
  (channelId?: string): AppThunk =>
  async (dispatch, getState) => {
    userService
      .getUsers(channelId)
      .then(res => {
        if (!res.data.ok) {
          const error = errorService.handleServerError(res)
          toastHelpers.handleToast(dispatch, error, SeverityEnum.ERROR)
          return
        }
        const users = res.data.data
        dispatch(updateUsers(users))
      })
      .catch(err => {
        const toastParams: ToastParams = {
          messages: messages.user.error_load_user,
          severity: SeverityEnum.WARNING
        }
        dispatch(createToast(toastParams))
      })
  }
export default userSlide.reducer
