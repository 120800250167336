export const messages = {
  standup: {
    not_select_channel: "Please select a channel to create a standup!",
    not_select_user: "Please select a user!",
    not_has_question: "Please type a question!",
    not_completed_question: "Please complete questions",
    not_select_timezone: "Please select a time zone",
    not_has_name: "Please type a standup name",
    not_has_question_random_values: "Please add values to a random question!",
    not_has_question_predefined_values:
      "Please add values to a predefined question!",
    not_has_enough_random_question:
      "Please provide at least 2 questions for a random question",
    not_has_enough_predefined_question:
      "Please provide at least 2 values for a predefined question",
    channels_success: "Your channel list has been updated!",
    standup_list_error:
      "Error while getting the standup list. Please try again!",
    standup_name_required_field: "The standup name field is required!",
    channel_name_required_select: "The channel name is required!",
    members_required_select: "The member list is required selected!",
    members_required_1_user_select: "Please select at least one user!",
    question_required_field: "The question field is required",
    question_required_least_1: "Must have at least one question",
    question_required_most_10: "Can only use 10 questions",
    not_has_limit: "Please type a limit",
    limit_must_be_number: "The limit must be a number",
    not_has_repeat: "Please select a repeat day",
    not_has_standup_days: "Please select a day",
    not_has_time_schedule: "Please select a standup time",
    not_has_opt_reminder_1: "Please select a remind time",
    not_has_opt_reminder_2: "Please select a remind time",
    not_has_time_zone_id: "Please select a timezone",
    greater_than_today: "Please select a date greater than today",
    not_has_format_report: "Please select a report format",
    report_channel_name_required_select:
      "The report channel name field is required",
    error_get_members: "Error while get standup members. Please try again!",
    error_get_standup: "Error while get standup. Please try again!",
    error_get_users: "Error while get members. Please try again!",
    error_get_channels: "Error while get your channels. Please try again!"
  },
  conversation: {
    response_required: "Please complete all required question",
    response_out_of_range: "Response is out of range",
    not_valid_end_vacation_date:
      "Your vacation return date must be after today",
    success: "Thank you for your responses",
    not_has_answer: "Please type an answer",
    not_has_answer_date: "Please select a date",
    not_has_predefined: "Please select a value",
    err_get_conversation: "Error while get your conversation, please try again!"
  },
  info: {
    deleted_standup: "Your standup has been removed!",
    delete_standup_err: "Error while deleting the standup, please try again!",
    updated_profile: "Your time zone has been updated!",
    create_standup: "A standup has been created!",
    create_standup_err: "Error while creating a new standup, please try again!",
    update_standup: "Your standup has been updated",
    update_standup_err: "Error while updating your standup, please try again!",
    err_data_report: "Error while getting reports, please try again!",
    cant_delete: "Please assign at least one admin to your team.",
    cant_permission:
      "It looks like you don't have permission to access Admin. Contact your Administrator for details.",
    not_has_code: "Please type a code",
    unknown_error: "Error while executing, please try again!",
    question_length: "Question length does not exceed 256 characters"
  },
  auth: {
    msteams: "Error while logging in Microsoft Teams. Please try again!",
    error: "Error while authenticating",
    errorHangout: "Error while authenticating with your Google account",
    success: "Login success",
    payment_update_success: "Success, Payment method is already updated !"
  },
  subscription: {
    not_accept_tos: "Please agree with the Terms of Services",
    subscribed: "Thank you for your subscription!",
    unsubscribe: "Your subscription was canceled!",
    invalid_email: "Email is invalid!",
    exists_email: "Email already exists",
    provide_info: "Please enter your billing address",
    not_select_user: "Please select a standup",
    updated_subscription: "Your subscription was updated",
    created_subscription: "Your subscription was created",
    loading: {
      subscribing: "Subcribing...",
      unsubscribing: "Unsubscribing...",
      updatePlan: "Updating plan...",
      updateCard: "Updating payment card...",
      updateBilling: "Updating billing address",
      updateTeam: "Updating team"
    },
    not_has_name_billing: "Please type a customer name",
    not_has_name_email: "Please type a customer email",
    not_has_name_address: "Please type a customer address",
    error_create_subs:
      "Error while creating your subscription, please try again!",
    error_update_subs:
      "Error while updating your subscription, please try again!",
    error_create_payment:
      "Error while creating your payment, please try again!",
    error_update_payment:
      "Error while updating your payment, please try again!",
    not_has_country: "Please select a country",
    error_get_subscription: "Error while get your billing, please try again!",
    error_get_invoice: "Error while get your invoices, please try again!"
  },
  user: {
    error_load_user:
      "Server is unavailable, please try later or contact Standup Alice support.",
    updated_timezone: "Your timezone has been updated",
    update_timezone_err: "Error while update your timezone, please try again!",
    updated_settings: "Your standup reminder settings has been updated",
    update_settings_err:
      "Error while update your standup reminder settings, please try again!",
    err_download_data:
      "Error while download your information, please try again!",
    updated_state: "Your state has been updated",
    err_updated_state: "Error while update your state, please try again!"
  },
  admin: {
    updated_admin: "Admins is updated",
    update_admin_error: "Error while update your admins, please try again!",
    updated_company_name: "Company name is updated",
    update_company_name_error:
      "Error while update your company name, please try again!",
    delete_admin_error: "Error while delete your admins, please try again!",
    deleted_admin: "Admin has been removed"
  }
}
